<template>
  <svg viewBox="0 0 24 24">
    <path
      d="M10,0 C15.5228475,0 20,4.4771525 20,10 C20,12.4013589 19.1535722,14.605024
      17.7427889,16.3289229 L23.7071068,22.2928932 C24.0976311,22.6834175 24.0976311,23.3165825
      23.7071068,23.7071068 C23.3466228,24.0675907 22.7793918,24.0953203 22.3871006,23.7902954
      L22.2928932,23.7071068 L16.3289229,17.7427889 C14.605024,19.1535722 12.4013589,20 10,20
      C4.4771525,20 0,15.5228475 0,10 C0,4.4771525 4.4771525,0 10,0 Z M10,2 C5.581722,2
      2,5.581722 2,10 C2,14.418278 5.581722,18 10,18 C14.418278,18 18,14.418278 18,10
      C18,5.581722 14.418278,2 10,2 Z">
    </path>
  </svg>
</template>
